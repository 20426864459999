// import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './styles/settings.scss'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { createApp } from 'vue'
// import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

// import 'vuetify/styles'
// import './styles/main.scss'

// import { createVuetify } from 'vuetify'
// import createVuetify from './plugins/vuetify'

import vuetify from '@/plugins/vuetify'

import { createPinia } from 'pinia'


import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';


import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import App from './App.vue'
import router from './routes'

const pinia = createPinia()
const app = createApp(App)
//
// const vuetifyApp = createVuetify({
//     components,
//     directives,
//     icons: {
//         defaultSet: 'mdi',
//         aliases,
//         sets: {
//             mdi
//         },
//     },
//     defaults:{
//         global:{
//             density: "comfortable",
//         },
//     },
//     theme: {
//         dark: false,
//         colors: {
//             background: '#000000',
//             surface: '#FFFFFF',
//             primary: 'blue',
//             'primary-darken-1': '#3700B3',
//             secondary: '#03DAC6',
//             'secondary-darken-1': '#018786',
//             error: '#B00020',
//             info: '#2196F3',
//             success: '#4CAF50',
//             warning: '#FB8C00',
//         },
//     }
// })

app.component('VueDatePicker', VueDatePicker);
app.use(pinia)
app.use(vuetify);
app.use(ToastPlugin, {
    position: 'top-right',
    duration: 2000
});

app.use(router);

app.directive('focus', {
    // When the bound element is mounted into the DOM...
    mounted(el) {
        // Focus the element
        el.focus()
    }
})

// Use plugin defaults (optional)
app.use(setupCalendar, {})

// Use the components
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)

app.mount('#app');
