<template>
  <div>


    <v-progress-linear
        :active="loadingPlans"
        color="primary"
        height="4"
        indeterminate
    ></v-progress-linear>
    <div v-if="!loadingPlans">


      <v-text-field v-model="libraryFilter"></v-text-field>
      <v-expansion-panels v-if="drillTree" style="overflow: scroll; height: calc(100vh - 100px)">
        <v-expansion-panel class="border"
                           no-gutters v-for="(tag) in drillTree"
                           :key="tag"
                           rounded="0"
        >
          <v-expansion-panel-title>
            {{ tag.tag }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list lines="one">
              <v-list-item
                  v-for="(drillItem) in tag.drills"
                  :key="drillItem"
                  :title=drillItem.name
                  :active="drillItem == drill"
                  @click="selectDrill(drillItem)"
                  density="compact"
              ></v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>

import api from "@/api";

export default {
  name: 'DrillLibraryTree',
  props: ['team'],

  created() {
    this.$watch(
        () => this.team,
        () => {
          this.loadDrills();
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    );

    this.$watch(
        () => this.libraryFilter,
        () => {
          console.log("filter update " + this.libraryFilter)
          let newFilterList = [];

          let filter = this.libraryFilter.toLowerCase();

          this.libraryDrills.forEach((drill) => {
            if ((drill.name && drill.name.toLowerCase().indexOf(filter) > -1) || (drill.notes && drill.notes.toLowerCase().indexOf(filter) > -1)) {
              newFilterList.push(drill);
            }
          })

          this.filteredLibraryDrills = newFilterList;

          this.setTagTree();
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  mounted() {
    this.loadDrills();
  },
  data() {
    return {
      libraryFilter: "",
      libraryDrills: [],
      filteredLibraryDrills: [],
      drill: null,
      drillTree: null,
      loadingPlans: false,
    }
  },
  watch: {
    // team: {
    //       handler() {
    //
    //       },
    //       deep: true
    //   },

  },

  methods: {
    setTagTree() {
      let filter = this.libraryFilter;

      let untagged = [];
      let tags = {};

      this.libraryDrills.forEach((drill) => {
        if ((drill.name && drill.name.indexOf(filter) > -1) || (drill.notes && drill.notes.indexOf(filter) > -1)) {
          if (!drill.tags || drill.tags.length === 0) {
            untagged.push(drill)
          } else {
            drill.tags.forEach((tag) => {
              if (!tags[tag]) {
                tags[tag] = []
              }

              tags[tag].push(drill)
            });
          }
        }
      })

      let organizedByTag = [];

      if (untagged.length > 0) {
        organizedByTag.push({
          tag: "Untagged",
          drills: untagged
        });
      }

      Object.keys(tags).sort().forEach((key) => {

        tags[key].sort((a, b) => {
          return a.name.localeCompare(b.name);
        })

        organizedByTag.push({
          tag: key,
          drills: tags[key],
        })
      });
      this.drillTree = organizedByTag;

    },
    selectDrill(drill) {
      this.drill = drill;
      this.$emit("drillSelected", drill);
    },
    loadDrills() {

      if (this.team) {
        let filter = this.libraryFilter;

        this.loadingPlans = true;
        api.drills.listForTeam(this.team.id, (drills) => {
          this.libraryDrills = drills;
          this.filteredLibraryDrills = drills;

          this.libraryFilter = filter;

          this.setTagTree();

          this.loadingPlans = false;
        })
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
     