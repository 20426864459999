<template>
  {{ value }}
</template>

<script>

function calculateSummary(drills) {
  if (!drills) {
    return "";
  }
  let drillNames = [];

  drills.forEach((d) => {
    drillNames.push(d.name)
  });

  return drillNames.join(", ")
}

export default {
  name: 'DrillListSummary',
  props: ['drills'],


  mounted() {
    this.value = calculateSummary(this.drills);
  },
  data() {
    return {
      value: "",
    }
  },
  watch: {
    drills: {
      handler() {
        this.value = calculateSummary(this.drills);
      },
      deep: true
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
     