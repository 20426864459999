<template>

  <v-navigation-drawer  v-model="showSummaryNav" v-if="loadingPlans === false">
    <v-row v-if="loadingPlans === false">
      <v-col cols="12" >
        <v-btn class='ma-1' @click="save" color="success" v-if="plan.team.id" density="default"  style="width:95%">Save</v-btn>
        <v-btn class='ma-1' @click="$router.go(-1)"  density="default"  style="width:95%">Cancel</v-btn>
        <!--        <v-btn class='ma-1' color="error" @click="deletePracticePlan()"> Delete</v-btn>-->
      </v-col>
    </v-row>

    <v-select v-if="teams.length > 1 && plan.team" label="Team" v-model="plan.team" density="default"  item-title="name" item-value="name"
              return-object :items="this.teams" ></v-select>
    <h1 v-if="teams.length === 1">{{ plan.team.name }}</h1>
    <h1>
      <v-text-field label="Practice Title" v-model="plan.name"></v-text-field>
    </h1>
    <v-textarea label="Notes" auto-grow rows="1" v-model="plan.notes"></v-textarea>
    <VDatePicker title-position="left" mode="date" v-model="plan.date" borderless/>
    <VDatePicker title-position="left" mode="time" v-model="plan.date" hide-time-header borderless time-accuracy=2 />
    <br>
    <b>Summary</b>
    <v-table>
      <tbody>
      <tr
          v-for="(value, name) in summary"
          :key="name"
      >
        <td>{{ name }}</td>
        <td>{{ value }}</td>
      </tr>
      </tbody>
    </v-table>
  </v-navigation-drawer>
  <v-navigation-drawer location= 'left' v-model="showMobileNav" v-if="loadingPlans === false">
    <v-row v-if="loadingPlans === false">
      <v-col cols="12" sm="11">
        <v-btn class='ma-1' @click="addTimeSlot" density="default" color="primary"  style="width:100%">Add Time Slot</v-btn>
        <v-btn class='ma-1' @click="addWaterBreak" density="default" color="indigo"   style="width:100%">Add Water Break</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-list lines="one" style="  width: 100%;">
        <v-list-item
            no-gutters v-for="(timeSlot, timeSlotIndex) in plan.timeSlots"
            :key="timeSlot"
            :class="{ 'bg-indigo': timeSlot.isBreak }"
            :active="timeSlot == selectedTimeSlot"
            @click="selectTimeslot(timeSlot, timeSlotIndex)"
            density="compact"
            style="border-bottom: solid 1px black"
        >
          <v-list-item-title>
            <TimeDisplay :practiceStartTime="plan.date" :timeSlots="plan.timeSlots" :index="timeSlotIndex"
                         :duration="timeSlot.duration">
            </TimeDisplay>

          </v-list-item-title>
          <v-list-item-subtitle>
            <div v-if="timeSlot.isBreak">
              Water Break
            </div>
            <DrillListSummary :drills="timeSlot.drills"/>
          </v-list-item-subtitle>

        </v-list-item>
<!--      -->
      </v-list>
    </v-row>
  </v-navigation-drawer>



  <v-main class="d-flex " style="min-height: 300px; height:95vh; overflow-y:auto">


    <v-container class="outer-container">
      <v-banner
          v-if="errorMessage"
          icon="mdi-alert-circle"
          color="error"
      >
        <template v-slot:text>
          {{ errorMessage }}
        </template>

        <template v-slot:actions>
          <v-btn @click="dismissError">
            Dismiss
          </v-btn>
        </template>
      </v-banner>

      <v-progress-linear
          :active="loadingPlans"
          color="primary"
          height="4"
          indeterminate
      ></v-progress-linear>
      <v-row v-if="this.$vuetify.display.mobile && loadingPlans === false">
          <v-btn
              color="primary"
              @click.stop="showSummaryNav = !showSummaryNav"
          >
            Show Overview
          </v-btn>
        <v-btn
            color="primary"
            @click.stop="showMobileNav = !showMobileNav"
        >
          Show Time Slots
        </v-btn>

      </v-row>
      <v-row v-if="selectedTimeSlot">


        <v-col class='pa-1'  cols="12">
          <TimeDisplay :practiceStartTime="plan.date" :timeSlots="plan.timeSlots" :index="selectedTimeSlotIndex"
                       :duration="selectedTimeSlot.duration"/>
          <v-tooltip text="Move time slot up">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" class='ma-1' v-if="selectedTimeSlotIndex > 0" size="32px"
                     @click="moveTimeSlotUp(selectedTimeSlotIndex)">
                <v-icon icon="mdi-arrow-up"></v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip text="Move time slot down">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" class='ma-1' v-if="selectedTimeSlotIndex < plan.timeSlots.length - 1" size="32px"
                     @click="moveTimeSlotDown(selectedTimeSlotIndex)">
                <v-icon
                    icon="mdi-arrow-down"></v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip text="Remove time slot">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" class='ma-1' size="32px" @click="removeTimeSlot(selectedTimeSlotIndex)">
                <v-icon color="red" icon="mdi-delete"></v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <br>

        </v-col>
        <v-col class='pa-1' cols="12" >
          <v-text-field type="number" label="Duration" v-model="selectedTimeSlot.duration"
                        hide-details="auto"></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="selectedTimeSlot">
        <v-col cols="12" sm="12" v-if="plan.team">

          <v-container>
            <v-row v-for="(drill, drillIndex) in selectedTimeSlot.drills" :key="drill" class="border-bottom">
              <v-col class='pa-1' cols="1">
                <v-row>
                  <v-col class='pa-1'  cols="12" >
                    <v-tooltip text="Move drill up">
                      <template v-slot:activator="{ props }">
                        <v-btn class='ma-1' v-bind="props" v-if="drillIndex > 0" size="32px"
                               @click="moveDrillUp(selectedTimeSlotIndex, drillIndex)">
                          <v-icon icon="mdi-arrow-up"></v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                    <v-tooltip text="Move drill down">
                      <template v-slot:activator="{ props }">
                        <v-btn class='ma-1' v-bind="props" v-if="drillIndex < selectedTimeSlot.drills.length - 1"
                               size="32px" @click="moveDrillDown(selectedTimeSlotIndex, drillIndex)">
                          <v-icon
                              icon="mdi-arrow-down"></v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                    <v-tooltip text="Remove drill">
                      <template v-slot:activator="{ props }">
                        <v-btn class='ma-1' size="32px" v-bind="props"
                               @click="removeDrill(selectedTimeSlotIndex, drillIndex)">
                          <v-icon color="red" icon="mdi-delete"></v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                    <v-tooltip text="Save to Drill Library">
                      <template v-slot:activator="{ props }">
                        <v-btn class='ma-1' v-bind="props" size="32px" @click="saveToDrillLibrary(drill)">
                          <v-icon icon="mdi-content-save-plus"></v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="11">
                <v-row>
                  <v-col class='pa-1' cols="12" sm="7">
                    <v-text-field label="Drill Name" v-model="drill.name" hide-details="auto"></v-text-field>
                  </v-col>
                  <v-col class='pa-1' cols="12" sm="5">
                    <v-select clearable chips label="Groups" v-model="drill.groups" :items="plan.team.groups"
                              multiple hide-details="auto"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class='pa-1' cols="12">
                    <v-textarea label="Notes" auto-grow
                                density="compact"
                                rows="1" v-model="drill.notes" hide-details="auto"></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" v-for="(image, imageIndex) in drill.images" :key="image">
                    <div style="display:grid">
                      <ImageDisplay :teamId="plan.team.id" :fileName="image"></ImageDisplay>
                      <v-btn @click="removeDrillImage(selectedTimeSlotIndex, drillIndex, imageIndex)">
                        <v-icon color="red" icon="mdi-delete"></v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="drill.links.length > 0">
                  <v-col cols="12">
                    <h3 >Links</h3>
                    <ul class="link-list">
                      <li v-for="(link, linkIndex) in drill.links" :key="link">
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-text-field label="Title" v-model="link.title"></v-text-field>
                          </v-col>
                          <v-col cols="10" sm="7">
                            <v-text-field label="Url" v-model="link.url"></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-btn @click="removeLink(selectedTimeSlotIndex, drillIndex, linkIndex)">
                              <v-icon color="red" icon="mdi-delete"></v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" >
                    <v-tooltip text="Add Image">
                      <template v-slot:activator="{ props }">
                        <v-btn class='ma-1' v-bind="props" size="32px"
                               v-if="plan.team.id"
                               @click="showImageAddDialog(selectedTimeSlotIndex, drillIndex)">
                          <v-icon icon="mdi-image-plus"></v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                    <v-tooltip text="Add Link">
                      <template v-slot:activator="{ props }">
                        <v-btn class='ma-1' v-bind="props" size="32px" @click="addLink(selectedTimeSlotIndex, drillIndex)">
                          <v-icon icon="mdi-link-variant"></v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </v-col>
                </v-row>


              </v-col>
            </v-row>
            <v-row no-gutters v-if="selectedTimeSlot.isBreak">
              <div cols="12" sm="12">
                Water Break
              </div>

            </v-row>
            <v-row>
              <v-btn class='ma-1' color="primary" @click="addDrill(selectedTimeSlotIndex)" v-if="!selectedTimeSlot.isBreak">Add Drill</v-btn>
              <v-btn class='ma-1' color="primary"  @click="showDrillLibrary(selectedTimeSlotIndex)" v-if="!selectedTimeSlot.isBreak">Add Drill From
                Library
              </v-btn>
            </v-row>
          </v-container>


        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="libraryDialog"
        width="auto"
        min-width="300px"
        height="90vh"
        :fullscreen="this.$vuetify.display.mobile"
        scrollable
    >
      <v-card style=" display: flex; align-items: stretch">
        <v-container  >
          <v-row  class="selected-drill" style="height:80vh" >
            <v-col cols="4" sm="4">
              <DrillLibraryTree :team="this.plan.team" @drillSelected="selectDrill" />
            </v-col>
            <v-col cols="8" sm="8" style="overflow: scroll; height:80vh;" v-if="selectedLibraryDrill">
              <DrillView :drill=selectedLibraryDrill :teamId="plan.team.id" :showImages="false" style=" overflow: scroll"></DrillView>
            </v-col>
          </v-row>

        </v-container>
        <v-card-actions>
          <v-btn color="primary" @click="addLibraryDrill" v-if="selectedLibraryDrill">Add Drill</v-btn>
          <v-btn color="" @click="libraryDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="showImageAdd"
        width="auto"
        min-width="300"
        :fullscreen="this.$vuetify.display.mobile"
    >
      <FileUpload v-if="showImageAdd" @uploadComplete="imageUploadComplete" :teamId=plan.team.id></FileUpload>
    </v-dialog>
  </v-main>

</template>

<script>
import TimeDisplay from '../components/TimeDisplay.vue'
import ImageDisplay from '../components/ImageDisplay.vue'
import DrillListSummary from '../components/DrillListSummary.vue'
import FileUpload from '../components/FileUpload.vue'
import {ref} from 'vue';
import api from '../../api'
import DrillView from "@/components/practiceplan/DrillView.vue";

import DrillLibraryTree from "../components/DrillLibraryTree.vue";


import { useDisplay } from 'vuetify'

var startTime = new Date();
startTime.setHours(18);
startTime.setMinutes(0);
startTime.setSeconds(0)

const newPlan = {
  "id": "new",
  "name": "",
  "date": ref(startTime),
  "team": {name: ""},
  "location": "",
  "notes": "",
  "timeSlots": [],
}

export default {
  name: 'PracticePlan',
  components: {
    DrillView,
    TimeDisplay,
    FileUpload,
    DrillListSummary,
    DrillLibraryTree,
    ImageDisplay,
  },
  created() {
    this.showSummaryNav = !useDisplay().value;
    this.showMobileNav = !useDisplay().value;

    // watch the params of the route to fetch the data again
    this.$watch(
        () => this.$route.params,
        () => {
          this.fetchPlan(this.$route.params.id);
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )

    this.$watch(
        () => this.plan.date,
        () => {
          this.dateChanged(this.$route.params.id);
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
    this.$watch(
        () => this.libraryFilter,
        () => {
          console.log("filter update " + this.libraryFilter)
          let newFilterList = [];

          let filter = this.libraryFilter;

          this.libraryDrills.forEach((drill) => {
            if ((drill.name && drill.name.indexOf(filter) > -1) || (drill.notes && drill.notes.indexOf(filter) > -1)) {
              newFilterList.push(drill);
            }
          })

          this.filteredLibraryDrills = newFilterList;
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )

    this.$watch(
        () => this.plan.team.id,
        () => {
          if (this.plan && this.plan.team) {
            api.drills.listForTeam(this.plan.team.id, (drills) => {
              this.libraryDrills = drills;
              this.filteredLibraryDrills = drills;
            })
          }
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  watch: {
    plan: {
      handler() {
        if(this.plan.timeSlots){
          this.summary = {};

          let totalTime = 0;
          let drillTime = 0;
          let breakTime = 0;

          this.plan.timeSlots.forEach((slot)=>{
            totalTime = totalTime + slot.duration;

            if(slot.isBreak){
              breakTime = breakTime+ slot.duration;
            }else {
              drillTime = drillTime+ slot.duration;
            }
          });

          this.summary["Total Time"] = totalTime;
          this.summary["Drill Time"] = drillTime;
          this.summary["Break Time"] = breakTime;
        }
      },
      deep: true
    }
  },
  methods: {
    dateChanged() {
      const titlePrefix = "Practice "


      if (this.plan.date && this.plan.date.toDateString && (this.plan.name == "" || (this.plan.name.indexOf(titlePrefix) === 0 && this.plan.name.length > titlePrefix.length))) {
        this.plan.name = titlePrefix + this.plan.date.toDateString();
      }
    },
    deletePracticePlan() {

    },
    fetchPlan(id) {
      this.error = null;
      this.loadingPlans = true;

      api.me.get((me, err) => {
        this.loadingTeams = false
        if (err) {
          this.error = err.toString()
        } else {
          this.teams = me.teams

          if (!id || id === "undefined" || id === "new") {
            this.plan = newPlan;

            if (me.teams.length === 1) {
              this.plan.team = me.teams[0];
            }

            this.loadingPlans = false;
          } else {
            api.practicePlans.get(id, (plan) => {
              this.plan = plan;
              this.startTime = new Date(plan.date).getHours();
              this.loadingPlans = false;

              let teamId = plan.team.id;
              me.teams.forEach((t) => {
                if (t.id == teamId) {
                  this.plan.team.groups = t.groups || [];
                }
              });

              this.plan.timeSlots.forEach((t) => {
                t.drills.forEach((d) => {
                  if (!d.groups) {
                    d.groups = [];
                  }
                });
              })

            })
          }

        }
      })

    },
    dismissError: function () {
      this.errorMessage = null;
    },
    cancel: () =>{
      this.plan = null;
      this.$router.push("/");
    },
    selectTimeslot(timeslot, timeSlotIndex) {
      this.selectedTimeSlot = timeslot;
      this.selectedTimeSlotIndex = timeSlotIndex;
    },

    selectDrill(drill){
      this.selectedLibraryDrill = drill;
    },
    save: function () {

      this.plan.timeSlots.forEach((t) => {
        t.duration = parseInt(t.duration)
      })

      api.practicePlans.save(this.plan.team.id, this.plan, (savedPlan) => {
        console.log(this.$router)
        this.$router.push({name: 'planview', params: {id: savedPlan.id}})
      }, () => {
        this.errorMessage = "Unable to save plan"
      });
    },
    moveTimeSlotDown(index) {

      if (index === this.plan.timeSlots.length - 1) { // if its the last in the array
        this.timeSlots.unshift(this.timeSlots.pop()) // same as option 1
      } else {
        this.plan.timeSlots = this.plan.timeSlots.map((element, i) => { // iterate array and change places
          if (i === index) {
            return this.plan.timeSlots[index + 1];
          } else if (i === index + 1) {
            return this.plan.timeSlots[index];
          } else {
            return element;
          }
        })
        this.selectedTimeSlotIndex++;
      }
    },
    moveTimeSlotUp(index) {
      if (index === 0) { // if its the first in the array
        this.plan.timeSlots.push(this.plan.timeSlots.shift()) // same as option 1
      } else {
        this.plan.timeSlots = this.plan.timeSlots.map((element, i) => { // iterate array and change places
          if (i === index - 1) {
            return this.plan.timeSlots[index];
          } else if (i === index) {
            return this.plan.timeSlots[index - 1];
          } else {
            return element;
          }
        })

        this.selectedTimeSlotIndex--;
      }
    },
    removeTimeSlot(index) {
      this.plan.timeSlots.splice(index, 1);

      this.selectedTimeSlot = null;
    },
    addTimeSlot() {
      this.expanded.push(1);
      this.plan.timeSlots.push({
        "duration": 15,

        "drills": [
          {
            "name": "",
            "notes": "",
            "links": [],
            "groups": []
          }
        ]
      })

    },
    addWaterBreak() {
      this.plan.timeSlots.push({
        "duration": this.plan.team.waterBreakLength || 5,
        "isBreak": true,
        "name": "Water Break"
      })

    },


    moveDrillDown(timeSlotIndex, drillIndex) {

      if (drillIndex === this.plan.timeSlots[timeSlotIndex].drills.length - 1) {
        return
      } else {
        this.plan.timeSlots[timeSlotIndex].drills = this.plan.timeSlots[timeSlotIndex].drills.map((element, i) => { // iterate array and change places
          if (i === drillIndex) {
            return this.plan.timeSlots[timeSlotIndex].drills[drillIndex + 1];
          } else if (i === drillIndex + 1) {
            return this.plan.timeSlots[timeSlotIndex].drills[drillIndex];
          } else {
            return element;
          }
        })
      }
    },
    moveDrillUp(timeSlotIndex, drillIndex) {
      if (drillIndex === 0) {
        return
      } else {
        this.plan.timeSlots[timeSlotIndex].drills = this.plan.timeSlots[timeSlotIndex].drills.map((element, i) => { // iterate array and change places
          if (i === drillIndex - 1) {
            return this.plan.timeSlots[timeSlotIndex].drills[drillIndex];
          } else if (i === drillIndex) {
            return this.plan.timeSlots[timeSlotIndex].drills[drillIndex - 1];
          } else {
            return element;
          }
        })
      }
    },
    removeDrill(timeSlotIndex, drillIndex) {
      this.plan.timeSlots[timeSlotIndex].drills.splice(drillIndex, 1);

    },
    removeDrillImage(timeSlotIndex, drillIndex, imageIndex) {
      this.plan.timeSlots[timeSlotIndex].drills[drillIndex].images.splice(imageIndex, 1);
    },
    addDrill(timeSlotIndex) {
      if (!this.plan.timeSlots[timeSlotIndex].drills) {
        this.plan.timeSlots[timeSlotIndex].drills = [];
      }
      this.plan.timeSlots[timeSlotIndex].drills.push({
        "name": "",
        "description": "",
        "notes": "",
        "links": [],
        "groups": []
      })

    },

    showDrillLibrary(timeSlotIndex) {
      this.selectedLibraryDrill = null;
      this.addFromLibraryToTimeSlotIndex = timeSlotIndex;
      this.libraryDialog = true
    },
    addLibraryDrill() {
      if (!this.plan.timeSlots[this.addFromLibraryToTimeSlotIndex].drills) {
        this.plan.timeSlots[this.addFromLibraryToTimeSlotIndex].drills = [];
      }

      let copy = JSON.parse(JSON.stringify(this.selectedLibraryDrill))
      this.plan.timeSlots[this.addFromLibraryToTimeSlotIndex].drills.push(copy);
      this.libraryDialog = false;
    },
    saveToDrillLibrary(drill) {
      api.drills.save(this.plan.team.id, drill, () => {
        this.$toast.open({message: 'Drill saved', type: 'success'});
        this.loadDrills();
      }), () => {
        this.$toast.open({message: 'Unable to save drill', type: 'error'});
      }
    },
    showImageAddDialog(timeSlotIndex, drillIndex) {
      this.showImageAdd = true
      this.addImageToDrill = drillIndex;
      this.addImageToTimeSlot = timeSlotIndex;
    },
    addLink(timeSlotIndex, drillIndex) {
      if (!this.plan.timeSlots[timeSlotIndex].drills[drillIndex].links) {
        this.plan.timeSlots[timeSlotIndex].drills[drillIndex].links = [];
      }

      this.plan.timeSlots[timeSlotIndex].drills[drillIndex].links.push({
        title: "",
        url: "",
      })
    },

    removeLink(timeSlotIndex, drillIndex, linkIndex) {
      this.plan.timeSlots[timeSlotIndex].drills[drillIndex].links.splice(linkIndex, 1);
    },

    imageUploadComplete(id) {
      console.log("upload complete: " + id)
      this.showImageAdd = false;

      if (!this.plan.timeSlots[this.addImageToTimeSlot].drills[this.addImageToDrill].images) {
        this.plan.timeSlots[this.addImageToTimeSlot].drills[this.addImageToDrill].images = []
      }

      this.plan.timeSlots[this.addImageToTimeSlot].drills[this.addImageToDrill].images.push(id);
    },
  },
  data() {
    return {
      loadingPlans: false,
      teams: [],
      plan: {
        team: {}
      },
      errorMessage: null,
      libraryDialog: false,
      libraryFilter: "",
      libraryDrills: [],
      selectedTimeSlot:null,
      selectedTimeSlotIndex:0,
      filteredLibraryDrills: [],
      selectedLibraryDrill: null,
      addFromLibraryToTimeSlotIndex: 0,
      showImageAdd: false,
      addImageToDrill: 0,
      addImageToTimeSlot: 0,
      showMobileNav: true,
      showSummaryNav: true,
      expanded: [],
      summary:{
        "Total Time": 19,
        "Drill Time": 19,
        "Break Time": 19,
      }
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.is-break {
  color: black;

}

.selected-drill {
  border-top: 1px solid rgb(128, 128, 128);
  min-height: 50vh;
  min-width: 50vw;
}

.link-list {
  list-style: none;
}

</style>
  