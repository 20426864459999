<template>

  <v-navigation-drawer  :location="$vuetify.display.mobile ? 'bottom' : 'left'" style="height: 100vh;">

    <v-sheet v-if="teams.length == 0">
      <v-progress-linear
          :active=true
          color="primary"
          height="4"
          indeterminate
      ></v-progress-linear>

      Loading Teams
    </v-sheet>

    <v-select v-if="teams.length > 1 " label="Team" v-model="team" item-title="name" item-value="name" return-object :items="this.teams"></v-select>
    <h1 v-if="teams.length === 1 && team">{{team.name}}</h1>
    <DrillLibraryTree :team="this.team" @drillSelected="selectDrill"/>
  </v-navigation-drawer>

  <v-main class="d-flex " style="min-height: 300px;">

    <v-container class="outer-container selected-drill" v-if="drill">
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <v-btn   @click="save" color="primary">Save</v-btn>
            <v-btn   @click="deleteDrill" color="error">Delete</v-btn>
          </v-col>
        </v-row>

        <v-row  style="height:calc(100vh - 100px);overflow: scroll">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="Drill Name" v-model="drill.name" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select clearable chips label="Groups"  v-model="drill.groups" :items="team.groups" multiple hide-details="auto"></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-tooltip text="Add Image">
                  <template v-slot:activator="{ props }">
                    <v-btn  v-bind="props" size="32px" @click="showImageAddDialog(timeSlotIndex, drillIndex)"> <v-icon icon="mdi-image-plus"></v-icon></v-btn>
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea label="Notes" auto-grow
                            rows="1" v-model="drill.notes" hide-details="auto"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select clearable chips label="Tags"  v-model="drill.tags" :items="tags" multiple hide-details="auto"></v-select>
              </v-col>
            </v-row>

            <v-row >
              <v-col cols="12" sm="4"  v-for="(image, imageIndex) in drill.images" :key="image">
                <div style="display:grid">
                  <ImageDisplay :teamId="team.id" :fileName="image" ></ImageDisplay>
                  <v-btn @click="removeDrillImage(imageIndex)">
                    <v-icon color="red" icon="mdi-delete"></v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>

          </v-col>

        </v-row>

    </v-container>
  </v-main>

  <v-container class="outer-container" v-show="false">
    <v-row no-gutters>
      <v-col cols="12" sm="4">
       Drill Library

        <v-container height="auto" style="display:flex">
          <v-row>
            <v-col cols="12" sm="11">
              <v-select v-if="teams.length > 1 " label="Team" v-model="team" item-title="name" item-value="name" return-object :items="this.teams"></v-select>
              <h1 v-if="teams.length === 1 && team">{{team.name}}</h1>
            </v-col>
          </v-row>
          <v-row v-if="team" style="">
            <v-col cols="12" sm="11">
              <v-select v-model="drill"  label="Drill" item-title="name" item-value="name" return-object :items="filteredLibraryDrills">
                <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field v-model="libraryFilter" ></v-text-field>
                  </v-list-item-content>
                </v-list-item>
                </template>

              </v-select>
            </v-col>
          </v-row>

        </v-container>
      </v-col>
      <v-col cols="12" sm="8" v-if="drill" class="selected-drill" >

            <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-btn class='ma-1' @click="save" color="primary">Save</v-btn>
                  <v-btn class='ma-1' @click="deleteDrill" color="error">Delete</v-btn>
                </v-col>
            </v-row>
            <v-row style="height: calc(100vh - 200px); overflow: scroll">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field label="Drill Name" v-model="drill.name" hide-details="auto"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select clearable chips label="Groups"  v-model="drill.groups" :items="team.groups" multiple hide-details="auto"></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-tooltip text="Add Image">
                      <template v-slot:activator="{ props }">
                        <v-btn class='ma-1' v-bind="props" size="32px" @click="showImageAddDialog(timeSlotIndex, drillIndex)"> <v-icon icon="mdi-image-plus"></v-icon></v-btn>
                      </template>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea label="Notes" auto-grow
                                rows="1" v-model="drill.notes" hide-details="auto"></v-textarea>
                  </v-col>
                </v-row>

                <v-row >
                  <v-col cols="12" sm="4"  v-for="(image, imageIndex) in drill.images" :key="image">
                    <div style="display:grid">
                      <ImageDisplay :teamId="team.id" :fileName="image" ></ImageDisplay>
                      <v-btn @click="removeDrillImage(imageIndex)">
                        <v-icon color="red" icon="mdi-delete"></v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

              </v-col>

            </v-row>




      </v-col>
    </v-row>


  </v-container>
  <v-dialog
      v-model="showImageAdd"
      width="auto"
      min-width="300"
  >
    <FileUpload v-if="showImageAdd" @uploadComplete="imageUploadComplete" :teamId=team.id></FileUpload>
  </v-dialog>
</template>
    
<script>

import api from '../api'
import FileUpload from "@/components/components/FileUpload.vue";
import ImageDisplay from "@/components/components/ImageDisplay.vue";
import DrillLibraryTree from "@/components/components/DrillLibraryTree.vue";

export default {
  name: 'DrillLibrary',
  components: {FileUpload, ImageDisplay, DrillLibraryTree},
  data() {
    return {
      libraryFilter: "",
      libraryDrills: [] ,
      filteredLibraryDrills: null,
      drill: null,
      loadingTeams: true,
      error: null,
      teams: [],
      team: null,
      showImageAdd: false,
      searchTerm: null,
      drillTree:[],
      tags: ["Core","Favorites",  "Battle", "Clinic",  "Conditioning","Cradling", "Defense","Dodging", "Footwork", "Give & Go", "Goalie", "Ground Balls", "Passing",
      "Shooting", "Offense", "Penalty Kill", "Scrimmage", "Small Area", "Transition", "Warm Up", "1 vs 1", "2 vs 0","2 vs 1", "2 vs 2", "3 vs 2", "3 vs 3", "4 vs 3", "5 vs 3", "5 vs 4", "5 vs 5"]

    }
  },
  created() {
    this.$watch(
        () => this.team,
        () => {
          this.loadDrills()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    )

    this.$watch(
        () => this.libraryFilter,
        () => {
          console.log("filter update " + this.libraryFilter)
          let newFilterList = [];

          let filter = this.libraryFilter;

          this.libraryDrills.forEach((drill) => {
            if((drill.name && drill.name.indexOf(filter) > -1) || (drill.notes && drill.notes.indexOf(filter) > -1)) {
              newFilterList.push(drill);
            }
          })

          this.filteredLibraryDrills = newFilterList;

          this.setTagTree();
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    )

    api.me.get((me, err) => {
      this.loadingTeams = false
      if (err) {
        this.error = err.toString()
      } else {
        this.teams = me.teams

        if(this.teams.length === 1){
          this.team = this.teams[0];
        }
      }

    });
  },
  methods: {
    setTagTree(){
      let filter = this.libraryFilter;

      let untagged = [];
      let tags= {};

      this.libraryDrills.forEach((drill) => {
        if((drill.name && drill.name.indexOf(filter) > -1) || (drill.notes && drill.notes.indexOf(filter) > -1)) {
          if(!drill.tags || drill.tags.length === 0){
            untagged.push(drill)
          } else {
            drill.tags.forEach((tag)=>{
              if(!tags[tag]){
                tags[tag] = []
              }

              tags[tag].push(drill)
            });
          }
        }
      })

      let organizedByTag = [];

      if(untagged.length > 0){
        organizedByTag.push({
          tag: "Untagged",
          drills: untagged
        });
      }

      Object.keys(tags).sort().forEach((key) => {
        organizedByTag.push({
          tag: key,
          drills: tags[key],
        })
      });
      this.drillTree = organizedByTag;

    },
    showImageAddDialog() {
      this.showImageAdd = true
    },

    imageUploadComplete(id) {
      console.log("upload complete: " + id)
      this.showImageAdd = false;

      if(!this.drill.images){
        this.drill.images = []
      }

      this.drill.images.push(id);
    },
    removeDrillImage(imageIndex) {
      this.drill.images.splice(imageIndex, 1);
    },
    selectDrill(drill){
      this.drill = drill;
    },
    save(){
      api.drills.save(this.team.id, this.drill, ()=>{
        this.$toast.open({ message: 'Drill saved', type: 'success' });
        this.drill = null;
        this.loadDrills();
      }), () =>{
        this.$toast.open({ message: 'Unable to save drill', type: 'error' });
      }
    },
    deleteDrill(){
      api.drills.delete(this.team.id, this.drill.id, ()=>{
        this.loadDrills();

        this.drill = null;
      }), () =>{
        this.$toast.open({ message: 'Unable to delete drill', type: 'error' });
      }
    },
    loadDrills(){
      if(this.team){
        let filter = this.libraryFilter;

        api.drills.listForTeam(this.team.id, (drills) =>{
          this.libraryDrills = drills;
          this.filteredLibraryDrills = drills;

          this.libraryFilter = filter;

          this.setTagTree();
        })
      }
    },
  }

}
</script>

<style scoped>


.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-list-item {
  margin: 0px !important;
  min-height: 0px !important;
  padding-left: 0px !important;

}

.v-list-item>>> .v-list-item-title {
  text-wrap: auto !important;
}

</style>